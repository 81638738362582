import { useEffect, useState } from "react";
import StandardSwiper from "../../components/swiper/standard";
import "./availability.scss";

const Availability = () => {
    const [activeFloorplan, setActiveFloorplan] = useState<number>(1);
    const [isSpacePlan, setIsSpacePlan] = useState<boolean>(false);
    const [backgroundPosition, setBackgroundPosition] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const docHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const isMobile = window.innerWidth <= 800;
        const scrollPixels =
            (scrollTop / docHeight) * 500 * (isMobile ? 1.5 : 3);
        setBackgroundPosition(scrollPixels);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (document.getElementById("orange")) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className="contain-wrapper">
                <div className="avail-one contain">
                    <StandardSwiper
                        slides={[
                            "../images/availability/gallery/1.jpg",
                            "../images/availability/gallery/2.jpg",
                            "../images/availability/gallery/3.jpg",
                            "../images/availability/gallery/4.jpg",
                            "../images/building/gallery/4.jpg",
                            "../images/building/gallery/5.jpg",
                        ]}
                    />
                    <div
                        style={{ top: `${backgroundPosition}px` }}
                        className="orange-box top-left"
                        id="orange"
                    >
                        <h4>
                            BRIGHT,
                            <br /> OPEN <br />
                            FLOORS
                        </h4>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper peach-back">
                <div className="avail-two contain">
                    <div className="title">
                        <h4>
                            AVAILABLE <br /> SPACE
                        </h4>
                    </div>
                    <div className="specification">
                        <div className="table">
                            <div className="row">
                                <div className="cell">
                                    <p>FLOOR</p>
                                </div>
                                <div className="cell">
                                    <p>SQ FT</p>
                                </div>
                                <div className="cell">
                                    <p>STATUS</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <p>6</p>
                                </div>
                                <div className="cell">
                                    <p>5,160</p>
                                </div>
                                <div className="cell">
                                    <p>Under Offer</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <p>4</p>
                                </div>
                                <div className="cell">
                                    <p>21,986</p>
                                </div>
                                <div className="cell">
                                    <p>Under Offer</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <p>3 (PART)</p>
                                </div>
                                <div className="cell">
                                    <p>6,974</p>
                                </div>
                                <div className="cell">
                                    <p>Available</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell">
                                    <p>2</p>
                                </div>
                                <div className="cell">
                                    <p>22,214</p>
                                </div>
                                <div className="cell">
                                    <p>Available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper dark-beige-back">
                <div className="avail-three contain">
                    <div className="btns">
                        <div
                            onClick={() => {
                                setActiveFloorplan(0);
                                setIsSpacePlan(false);
                            }}
                            className={`btn ${
                                activeFloorplan === 0 ? "active" : ""
                            }`}
                        >
                            <div className="details-wrapper">
                                <h1>04</h1>
                                {activeFloorplan === 0 && (
                                    <div className="detail">
                                        <h4 className="beige">&nbsp;</h4>
                                        <h4>21,986 SQ FT</h4>
                                        <h4>2043 SQ M</h4>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setActiveFloorplan(1);
                                setIsSpacePlan(false);
                            }}
                            className={`btn ${
                                activeFloorplan === 1 ? "active" : ""
                            }`}
                        >
                            <div className="details-wrapper">
                                <h1>03</h1>
                                {activeFloorplan === 1 && (
                                    <div className="detail">
                                        <h4 className="beige">(PART)</h4>
                                        <h4>6,7974 SQ FT</h4>
                                        <h4>648 SQ M</h4>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setActiveFloorplan(2);
                                setIsSpacePlan(false);
                            }}
                            className={`btn ${
                                activeFloorplan === 2 ? "active" : ""
                            }`}
                        >
                            <div className="details-wrapper">
                                <h1>02</h1>
                                {activeFloorplan === 2 && (
                                    <div className="detail">
                                        <h4 className="beige">&nbsp;</h4>
                                        <h4>22,214 SQ FT</h4>
                                        <h4>2,063 SQ M</h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="floorplan">
                        <img
                            src={`../images/availability/${
                                isSpacePlan ? "spaceplans" : "floorplans"
                            }/${activeFloorplan}.${
                                activeFloorplan === 1 && !isSpacePlan
                                    ? "png"
                                    : "svg"
                            }`}
                            alt="floorplan"
                        />
                    </div>
                    <div className="key">
                        <div className="key-item">
                            <div
                                className="color"
                                style={{ backgroundColor: "#E0D9C9" }}
                            ></div>
                            <p>OFFICE</p>
                        </div>
                        <div className="key-item">
                            <div
                                className="color"
                                style={{ backgroundColor: "#8A5F49" }}
                            ></div>
                            <p>CORE</p>
                        </div>
                        <div className="key-item">
                            <div
                                className="color"
                                style={{ backgroundColor: "#BD8164" }}
                            ></div>
                            <p>ATRIUM</p>
                        </div>
                        <div className="key-item">
                            <div
                                className="color"
                                style={{ backgroundColor: "#BF8F29" }}
                            ></div>
                            <p>
                                INTERCONNECTING <br /> STAIRCASE
                            </p>
                        </div>
                    </div>
                    <div className="space-btns">
                        <div
                            onClick={() => setIsSpacePlan(false)}
                            className={`space-btn ${
                                isSpacePlan ? "" : "active"
                            }`}
                        >
                            <p>FLOOR PLAN</p>
                        </div>
                        {

                            activeFloorplan != 0 &&
                            <div
                                onClick={() => setIsSpacePlan(true)}
                                className={`space-btn ${
                                    isSpacePlan ? "active" : ""
                                }`}
                            >
                                <p>SPACE PLAN</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="contain-wrapper dark-beige-back">
                <div className="avail-four contain avail-foot">
                    <img
                        src="../images/availability/gallery/5.jpg"
                        alt="avail"
                    />
                    <div className="avail-foot-caption">
                        Second and third floors
                    </div>
                </div>
            </div>
        </>
    );
};

export default Availability;
