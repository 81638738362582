import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./narbar.scss";
import { motion } from "framer-motion";

const Nav: React.FC = () => {
    const location = useLocation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const topLine = {
        open: {
            y: 6,
            rotate: 45,
        },
        closed: {
            y: 0,
            rotate: 0,
        }
    }
    const bottomLine = {
        open: {
            y: -7,
            rotate: -45,
        },
        closed: {
            y: 0,
            rotate: 0,
        }
    }

    const menuItems = (x: number) => ({
        initial: {
            opacity: 0,
            y: 25,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                delay: x / 5,
            }
        }
    });

    return (
        <>
        <div className="contain-wrapper brown-back" style={{position: 'fixed', zIndex: '100'}}>            
            <div className='navbar-wrapper contain'>
                <Link
                    to='/'
                    className={location.pathname === "/" ? "active" : ""}
                >
                    <img src="../images/logo.svg" alt="logo" />
                </Link>
                <div className='main-nav'>
                    <Link
                        to='/building'
                        className={
                            location.pathname === "/building" ? "active" : ""
                        }
                    >
                        <p>Building</p>
                    </Link>
                    <Link
                        to='/location'
                        className={
                            location.pathname === "/location" ? "active" : ""
                        }
                    >
                        <p>Location</p>
                    </Link>
                    <Link
                        to='/availability'
                        className={
                            location.pathname === "/availability" ? "active" : ""
                        }
                    >
                        <p>availability</p>
                    </Link>          
                    <a
                        href={`${window.location.pathname}/#contact`}
                        className={
                            location.pathname === "/contact" ? "active" : ""
                        }
                    >
                        <p>Contact</p>
                    </a>
                </div>
            </div>
            </div>
            <div onClick={() => setIsOpen(!isOpen)} className="burger">
                <motion.div animate={isOpen ? 'open' : 'closed'} initial='closed' variants={topLine} className="line" />
                <motion.div  animate={isOpen ? 'open' : 'closed'} initial='closed' variants={bottomLine} className="line" />                
            </div>
            {isOpen && (
                
                <motion.div initial={{x: '-100vw' }} animate={{x: '0'}} transition={{effect: 'ease'}} className="mobile-nav">
                    <Link
                        to='/'
                        className={
                            location.pathname === "/" ? "active" : ""

                        }
                        onClick={() => setIsOpen(false)}
                    >
                        <motion.h4 variants={menuItems(1)} whileInView={'animate'} initial={'initial'}>Home</motion.h4>
                    </Link>
                    <Link
                        to='/building'
                        className={
                            location.pathname === "/building" ? "active" : ""

                        }
                        onClick={() => setIsOpen(false)}
                    >
                        <motion.h4 variants={menuItems(2)} whileInView={'animate'} initial={'initial'}>Building</motion.h4>
                    </Link>
                    <Link
                        to='/location'
                        className={
                            location.pathname === "/location" ? "active" : ""
                        }
                        onClick={() => setIsOpen(false)}
                    >
                        <motion.h4 variants={menuItems(3)} whileInView={'animate'} initial={'initial'}>Location</motion.h4>
                    </Link>
                    <Link
                        to='/availability'
                        className={
                            location.pathname === "/availability" ? "active" : ""
                        }
                        onClick={() => setIsOpen(false)}
                    >
                        <motion.h4 variants={menuItems(4)} whileInView={'animate'} initial={'initial'}>Availability</motion.h4>
                    </Link>
                </motion.div>
            )}

        </>
    );
};

export default Nav;
